import * as React from 'react';
import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import SignIn from '../components/login';
import SignUp from '../components/regist';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography } from '@mui/material';
import MyInformation from '../components/MyInformation';

const apiUrl = 'https://aipictw.aimor.site';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function MyInfo(props){
    //const {currentMenu, setCurrentmenu} = props;
    const [isLogin,setIsLogin] = useState();
    const [uid,setUid] = useState();
    const [menu,setMenu] = useState('login');
    const [loaded,setLoaded] = useState(false);

    useEffect(()=>{
        setUid(localStorage.getItem('uid'));
        setLoaded(true);
    },[uid]);

    function logout(){
        // let answer = window.confirm('로그아웃 합니까?');
        // if(!answer) return false;
        localStorage.clear();
        setUid('');
        setMenu('login');
    }
    
    return (
        <div>
            {(loaded && !uid && menu == 'login')&& 
                <SignIn menu={menu} setMenu={setMenu} apiUrl={apiUrl} uid={uid} setUid={setUid}/>
            }
            {(loaded && !uid && menu == 'regist')&& 
                <SignUp menu={menu} setMenu={setMenu}  apiUrl={apiUrl} uid={uid} setUid={setUid}/>
            }

            {(loaded && uid) && 
                <MyInformation logout={logout}/>
                
            }
        </div>

        
    );
}