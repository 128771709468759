import * as React from 'react';
import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header(props){
    return (
        <div style={{
            width:'99vw',
            maxWidth: '500px',
            minWidth: '350px',
            padding:"10px 0px",
            minHeight:"30px",
            position:"fixed",
            top:"0px",
            backgroundColor:"#fff",
            zIndex:'999',
            borderBottom:'1px solid #EAE7EC'
            }}>
            <img src="./asset/main_logo.png" style={{maxHeight:"30px"}}/>
        </div>
    );
}