import * as React from 'react';
import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import { Backdrop, CircularProgress,Container} from '@mui/material';
import axios from 'axios';

import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import ImageComponent from './ImageComponent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchModal from './SearchModal';
import useWindowSize from '../include/windowsSize';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const apiUrl = 'https://aipictw.aimor.site';

export default function MasonImageList() {

const [itemData,setItemData] = useState([]);
const [loading,setLoading] = useState(true);
const [isFirst,setIsFirst] = useState(true);

const [filter,setFilter] = useState(''); //초기는 검색분류는 없다.
const [keyword,setKeyword] = useState(''); //초기는 검색어는 없다.

const [sst,setSst] = useState('seq_num'); //초기 순서는 regdate로 한다.
const [sst2,setSst2] = useState('desc'); //초기는 순서는 내림차순으로 한다.

const [page,setPage] = useState(1); //1페이지부터 설정
const [offset,setOffset] = useState(50); //초기 offset은 50

const [scrlLock,setScrlLock] = useState(false); //이미지 로딩전에 계속 밑으로 내리려는 시도 잠금

const [favorites,setFavorites] = useState([]);

//검색모달 관련
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);

//화면크기 관련
const { width, height } = useWindowSize();

useEffect(()=>{
    //사용자의 Favorite정보를 불러와야 함. (uid/username/m_type을 임의로 조작할 수 없음)
    if(localStorage.getItem('uid')){
        var uid_feed = localStorage.getItem('uid');
        const postData ={
            uid : uid_feed
        };
        const member_res = async ()=> axios.post(apiUrl+'/api/getUsrInfobyUid',postData)
        .then((response)=>{
            if(response.data.member.favorites){
                var favorite_arr = response.data.member.favorites.split(',');
                setFavorites(favorite_arr);
            }
        }).catch((error)=>{
            console.log(error);
        });
        member_res();
    }


},[]);

// useEffect(()=>{
//     console.log(width+'/'+height);
// },[width,height])

//키워딩 감지 useEffect
useEffect(()=>{

    const postData = {
        filter  : 'hashtag',
        keyword : keyword,
        sst : sst,
        sst2 : sst2,
        page : 1,
        offset : offset
    }

    axios.post(apiUrl+'/api/getPaintsAll',postData).then((response)=>{
        //console.log(response);
        var result = response.data.result;
        setItemData(result);
        setLoading(false);
        setIsFirst(false);
        if(result.length<50){
            setScrlLock(true);
        }else{
            setScrlLock(false);            
        }
    }).catch((error)=>{
        console.log(error);
    });

},[keyword]);


//Bottom reached(Page 변화) 시 useEffect
useEffect(()=>{
    if(!isFirst){
        setLoading(true); 

        const postData = {
            filter  : 'hashtag',
            keyword : keyword,
            sst : sst,
            sst2 : sst2,
            page : page,
            offset : offset
        }

        axios.post(apiUrl+'/api/getPaintsAll',postData).then((response)=>{
            //console.log(response);
            setItemData(itemData.concat(response.data.result));
            setLoading(false);
            setScrlLock(false);
        }).catch((error)=>{
            console.log(error);
        });
    }
},[page]);

// useEffect(()=>{
//    console.log(width+'/'+height);
// },[width,height])


//맨 밑에 닿을때 page 변화
const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom&&!scrlLock) {
    // bottomReached Load 기능 모바일에서 잘 작동이 되지 않는 오류 있음. 잠시 보류
    // console.log(itemData.length);
    // console.log('Bottom reached');
    //    setPage(page+1);
    //    setScrlLock(true);
    }
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
  
  return (
    <div>
    <Box onScroll={handleScroll} sx={width>600 ?{ width: 350,height:height-60, overflowY: 'scroll', padding:"0",margin:'0 auto'} : { width: width-30,height:height-60, overflowY: 'scroll', padding:"0",margin:'0 auto'}} >
        {itemData && 
        <Grid container spacing={2} sx={{marginTop:'10px'}}>
            <Grid item xs={12}>
                <Item>
                    <Typography variant='subtitle2' color="inherit">
                        <PhotoLibraryIcon sx={{fontSize:'0.9em'}}/> 모든 사진
                    </Typography>
                </Item>
            </Grid>
        </Grid>
        }
        {itemData &&
        <div>
            <ImageList 
                sx={ width>600? {width: 350, padding:0} : {width : width-30, padding:0}}
                // variant="woven"
                cols={2}
                rowHeight={260}
                >
                    {itemData.map((item,index) => (
                        <ImageComponent key={item.code} item={item} favorites={favorites}/>
                    ))}
            </ImageList>
            <Button 
                variant="outlined" 
                size="large" 
                onClick={()=>setPage(page+1)} 
                sx={{marginBottom:"15px"}}
                startIcon={<RefreshIcon/>}
                >더 보기</Button>
        </div>
        }
      
      {loading && 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                
                <CircularProgress color="inherit" sx={{margin:"20px auto"}}/>
                <span>앨범을 불러오는 중입니다...</span>                
            </div>
            </Backdrop>}
      
    </Box>
    <Fab size="large" aria-label="add" sx={{position:'fixed',right:'10px',bottom:'60px',color:'#ffffff',bgcolor:'#B82647'}}>
        <SearchIcon onClick={handleOpen}/>
    </Fab>
    <SearchModal open={open} setOpen={setOpen} setKeyword={setKeyword} />

    </div>
  );
}


