import * as React from 'react';
import { styled } from '@mui/material/styles';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Backdrop, Box, CircularProgress,Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import RecipeReviewCard from '../include/RecipeReviewCard';


const apiUrl = 'https://aipictw.aimor.site';

export default function FeedPage(){

    const [mload,setMload] = useState(false);


    const [uid,setUid] = useState();
     
    const [favorites,setFavorites] = useState([]);

    //임시
    const [data, setData] = useState(null);

    //데이터 로딩 여부
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        //feeds의 내용이 바뀌는 것을 감지하는 useEffect
        const fetchData = async () => {
            try {
                // 예를 들어, Laravel 서버가 http://localhost:8000 에서 실행 중이라고 가정
                const response = await axios.post(apiUrl+'/api/getFeed');
                setData(response.data.result);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();

        //사용자의 Favorite정보를 불러와야 함. (uid/username/m_type을 임의로 조작할 수 없음)
        if(localStorage.getItem('uid')){
            var uid_feed = localStorage.getItem('uid');
            const postData ={
                uid : uid_feed
            };
            const member_res = async ()=> axios.post(apiUrl+'/api/getUsrInfobyUid',postData)
            .then((response)=>{
                setUid(uid_feed);
                if(response.data.member.favorites){
                    var favorite_arr = response.data.member.favorites.split(',');
                    setFavorites(favorite_arr);
                }
            }).catch((error)=>{
                console.log(error);
            });
            member_res();
        }
        setMload(true);
    },[])
    


    return (
        <Box>
            {loading && 
            <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                {/* For other variants, adjust the size with `width` and `height` */}
                {/* <Skeleton variant="circular" width={40} height={40} /> */}
                <Skeleton variant="rectangular" height={500} sx={{ margin:'0 auto' }} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} animation="wave"/>
                {/* <Skeleton variant="rounded" width={210} height={60} /> */}
            </Stack>
            }
            {loading && 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                
                <CircularProgress color="inherit" sx={{margin:"20px auto"}}/>
                <span>피드를 불러오는 중입니다...</span>                
            </div>
            </Backdrop>}
            {data && mload && 
            <Grid container spacing={1}>
                {data.map((item) => (
                    <Grid item xs={12} key={item.code} sx={{marginTop:'20px'}}>
                        <RecipeReviewCard uid={uid} seq_num={item.seq_num} p_file={item.p_file} hashtag={item.hashtag} code={item.code} regdate={item.regdate} v_cnt={item.v_cnt} fav_cnt={item.fav_cnt} cmt_cnt={item.cmt_cnt} favorites={favorites} setFavorites={setFavorites}/>
                    </Grid>
                ))}
            </Grid>
            }
        </Box>
        
    );
}