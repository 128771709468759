import * as React from 'react';
import {useState,useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://aipictw.aimor.site/"  target="_blank">
        Aista in Aimor
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';'); // 쿠키를 세미콜론으로 분리하여 배열로 만듭니다.
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length); // 문자열 앞의 공백을 제거합니다.
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length); // 쿠키의 이름이 일치하면 값을 반환합니다.
    }
    return null; // 일치하는 쿠키 이름이 없다면 null을 반환합니다.
  }

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn(props) {
  const [username,setUsername] = useState();
  const {menu,setMenu,apiUrl,uid,setUid}  = props;
  const [isRemember, setIsRemember] = useState(false);
  const [submit_err,setSubmit_err] = useState();
  const [err_msg,setErr_msg] = useState();
  const [req_load,setReq_load] = useState(false);
  const [regOk,setRegOk] = useState(false);


  useEffect(()=>{
    var username = getCookie('rememberName');

    if(username){
        setUsername(username);
        setIsRemember(true);
    }

  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const postData = {
        username: data.get('username'),
        password: data.get('password'),
    };

    if(data.get('username') == '' || data.get('username')==='undefined' || data.get('password') == '' || data.get('password') === 'undefined'){
        setSubmit_err(true);
        setErr_msg('입력되지 않은 정보가 있습니다.');
    }

    //아이디 비번에 이상이 없으면 axios를 시도한다.
    setSubmit_err(false);
    setReq_load(true);

    axios.post(apiUrl+'/api/loginUser',postData)
    .then((response)=>{        
        const data = response.data;
        if(data.err_cd != 200){
            setSubmit_err(true);
            setRegOk(false);
            setErr_msg(data.err_msg);
            setReq_load(false);
        }else{
            const username = response.data.member.username;
            const uid = response.data.member.uid;
            const m_type = response.data.member.m_type;

            setRegOk(true);
            setErr_msg(data.err_msg);
            setReq_load(false);
            localStorage.setItem('username',username);
            localStorage.setItem('uid',uid);
            localStorage.setItem('m_type',m_type);
            if(isRemember){
                setCookie('rememberName', username, 7); // 'username' 쿠키를 7일 동안 유효하도록 설정
            }else{
                setCookie('rememberName', username, -1); // 'username' 쿠키를 7일 동안 유효하도록 설정
            }
            setTimeout(()=>{
                setUid(uid);
            },1500);

        }
    }).catch((error)=>{
        console.log(error);
    });
    
    
  };

//   const handleCheckboxChange = (event) => {
//     // 사용자가 체크박스를 클릭할 때 상태를 업데이트합니다.
//     setIsRemember(event.target.checked);
//   };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{height:"80vh"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Container maxWidth="sm">
            <img src="./asset/main_logo.png" style={{width:'50%'}}/>
          </Container>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value= {username ? username : ''}
              error = {submit_err ? true : false}
              onChange = {(e)=>setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error = {submit_err ? true : false}
            />
            <FormControlLabel
              control={<Checkbox name='remember' color="primary" checked={isRemember} onChange={()=>setIsRemember(!isRemember)} /> }
              label="아이디 기억하기"
              
            />
            {submit_err && 
            <Grid item xs={12}>
                <Alert severity="error">{err_msg}</Alert>
            </Grid>
            }
            {regOk && 
            <Grid item xs={12}>
                <Alert severity="success">{err_msg}</Alert>
            </Grid>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              로그인
            </Button>
          </Box>
        </Box>
        <Link href="#" variant="body2" sx={{fontSize:'0.7em'}} onClick={(()=>setMenu('regist'))}>
            아이디가 없으신가요?
        </Link>
        <Grid item xs={12}>
            <Typography variant='secondary' sx={{fontSize:'0.8em'}}>
                Aista는 사용자의 아이디, 비밀번호를 제외한 개인정보를 일체 수집하지 않습니다. 따라서 비밀번호 분실 시 관리자에 의한 재설정이 필요할 수 있습니다.<u>poxdkrkrkrkr@gmail.com</u>으로 문의주시기 바랍니다.
            </Typography>
        </Grid>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={req_load}
            onClick={()=>setReq_load(false)}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}