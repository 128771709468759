import * as React from 'react';
import { Container, SpeedDial } from '@mui/material';
import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import FavImageList from '../components/favImageList';

export default function FavoritePage(props){

    const {currentMenu,setCurrentmenu} = props;

    return (
        <Container sx={{height: '90vh', position: 'relative',overflow: 'auto',padding:'2px' }}>
            <FavImageList currentMenu={currentMenu} setCurrentmenu={setCurrentmenu} />
        </Container>
    );
}