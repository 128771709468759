import * as React from 'react';
import {useState,useEffect} from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SearchIcon from '@mui/icons-material/Search';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import FavoriteIcon from '@mui/icons-material/Favorite';



export default function LabelBottomNavigation(props) {
  const [value, setValue] = React.useState('feed');
  const {currentMenu,setCurrentmenu,history,setHistory}  = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentmenu(newValue);
    var prevHist = history;
    if(prevHist.includes(newValue)){
      //prevHistory에 있는 값중 newvalue와 같다면 배열에서 삭제하기 
      prevHist = prevHist.filter(item => item !== newValue);
    }
    prevHist.push(newValue);
    console.log(prevHist);
    setHistory(prevHist);
  };
  
  React.useEffect(()=>{
    setValue(currentMenu);
    setCurrentmenu(currentMenu);
  },[currentMenu])

  useEffect(()=>{
    console.log('history',history);
  },[history])



  return (
    <div>
        <BottomNavigation value={value} onChange={handleChange} sx={{
            width:'99.5vw',
            position: 'fixed', // 위치를 고정
            bottom: 0, // 화면 하단에 배치
            left: 0, // 왼쪽에 맞춤
            right: 0, // 오른쪽에 맞춤
            zIndex: 1000, // 다른 요소들보다 위에 오도록 z-index 설정
            border:'1px solid #EAE7EC',
            maxWidth: '500px',
            minWidth: '350px',
            margin:'0 auto'
        }}>
        <BottomNavigationAction 
            label="새 피드"
            value="feed"
            icon={<FiberNewIcon />}
            style={{ color: currentMenu === 'feed' ? '#B82647' : 'inherit'}}
            sx ={{fontSize:'0.5em'}}
        />
        <BottomNavigationAction
            label="앨범"
            value="search"
            icon={<PhotoLibraryIcon />}
            style={{ color: currentMenu === 'search' ? '#B82647' : 'inherit' }}
        />
        <BottomNavigationAction 
            label="좋아요"
            value="favorite"
            icon={<FavoriteIcon />}
            style={{ color: currentMenu === 'favorite' ? '#B82647' : 'inherit' }}
        />
        <BottomNavigationAction
            label="내 정보"
            value="myinfo"
            icon={<PermIdentityIcon />}
            style={{ color: currentMenu === 'myinfo' ? '#B82647' : 'inherit' }}
        />
        </BottomNavigation>
    </div>
  );
}