import * as React from 'react';
import {useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Stack, Typography,CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';

const apiUrl = 'https://aipictw.aimor.site';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}

export default function MailComponent(props){

    const [userMail,setUserMail] = useState();
    const [subject,setSubject] = useState();
    const [qustContent,setQustContent] = useState();
    const [qIsDone,setQIsDone] = useState(false);
    const [loading, setLoading] = useState(false); // 이메일 전송 중 상태
    const [error, setError] = useState(''); // 에러 메시지 상태

    function sendQna(){
        // 유효성 검사
        if (!subject.trim()) {
            setError('문의 제목을 입력해 주세요.');
            return;
        }
        if (!validateEmail(userMail)) {
            setError('유효하지 않은 이메일 주소입니다.');
            return;
        }
        if (!qustContent.trim()) {
            setError('문의 내용을 입력해 주세요.');
            return;
        }

        // 에러 상태 초기화
        setError('');

        // 이메일 전송 로직 (예시)
        setLoading(true);

        axios.post(apiUrl+'/api/questionSubmit', { subject:subject,userMail:userMail, qustContent:qustContent })
            .then(response => {
                // 성공 처리
                setLoading(false);
                // 성공 메시지 또는 로직
                //console.log(response);
                setQIsDone(true);
                setSubject('');
                setUserMail('');
                setQustContent('');
            })
            .catch(error => {
                // 오류 처리
                setLoading(false);
                setError('이메일 전송에 실패했습니다.');
                //console.log(error);
            });
    }

    return (
        <Box sx={{border:'1px solid #dedede', maxHeight:"350px", overflowY:'scroll',padding:'20px 0px'}}>
            {!qIsDone && 
            <Grid container spacing={2} sx={{padding:'5px 10px'}}>                                        
                <Grid item xs={12}>
                    <TextField
                        label="문의 제목"
                        id="outlined-size-small"
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        size="small"
                        sx={{width:'100%'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="회신받을 이메일"
                        id="outlined-size-small"
                        value={userMail}
                        onChange={(e)=>setUserMail(e.target.value)}
                        size="small"
                        sx={{width:'100%'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    label="개발자에게 문의할 내용"
                    id="outlined-size-small"
                    value={qustContent}
                    onChange={(e)=>setQustContent(e.target.value)}
                    multiline 
                    rows={4} 
                    sx={{width:'100%',minHeight:'100px',maxHeight:'450px'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!loading && 
                        <IconButton sx={{color:'#333',fontWeight:'700', width:'30%', display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-evenly',margin:'0 auto'}}
                        onClick={sendQna}
            >
                            <Typography variant='subtitle1'>전송</Typography>
                            <SendIcon/> 
                        </IconButton>
                    }
                    {loading && 
                        <IconButton sx={{color:'#333',fontWeight:'700', width:'30%', display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-evenly',margin:'0 auto'}}
            >
                            <Typography variant='subtitle1'>전송중...</Typography>
                            <SendIcon/> 
                        </IconButton>
                    }
                    
                </Grid>
            </Grid> 
            }
            {error && 
            <Grid container spacing={2} sx={{padding:'5px 10px'}}>                                        
                <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                </Grid>
            </Grid> 
            }
            {qIsDone && 
            <Grid container spacing={2} sx={{padding:'5px 10px'}}>                                        
                <Grid item xs={12}>
                    <Alert severity="success">문의가 접수되었습니다! 최대한 신속하게 답변드릴게요!</Alert>
                </Grid>
            </Grid> 
            }
            
            
        </Box>
    );
}