import * as React from 'react';
import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import { Backdrop, CircularProgress,Stack} from '@mui/material';
import axios from 'axios';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';

import ImageComponent from './ImageComponent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SearchModal from './SearchModal';
import ViewModal from './viewModal';
import useWindowSize from '../include/windowsSize';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';

const apiUrl = 'https://aipictw.aimor.site';

export default function FavImageList(props) {

const [itemData,setItemData] = useState([]);
const [loading,setLoading] = useState(true);
const [isFirst,setIsFirst] = useState(true);

const [keyword,setKeyword] = useState(''); //초기는 검색어는 없다.

const [sst,setSst] = useState('seq_num'); //초기 순서는 regdate로 한다.
const [sst2,setSst2] = useState('desc'); //초기는 순서는 내림차순으로 한다.

const [page,setPage] = useState(1); //1페이지부터 설정
const [offset,setOffset] = useState(50); //초기 offset은 50

const [scrlLock,setScrlLock] = useState(false); //이미지 로딩전에 계속 밑으로 내리려는 시도 잠금
const [favorites,setFavorites] = useState();

//검색모달 관련
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);

//화면크기 관련
const { width, height } = useWindowSize();

//로그인 관련
const [isUser,setIsUser] = useState(true);
const {currentMenu,setCurrentmenu} = props;


useEffect(()=>{
    //사용자의 Favorite정보를 불러와야 함. (uid/username/m_type을 임의로 조작할 수 없음)

    if(localStorage.getItem('uid')){
        var uid_feed = localStorage.getItem('uid');
        const postData ={
            uid : uid_feed
        };
        const member_res = async ()=> axios.post(apiUrl+'/api/getUsrInfobyUid',postData)
        .then((response)=>{
            if(response.data.member.favorites){
                var favorite_arr = response.data.member.favorites.split(',');
                setFavorites(favorite_arr);
            }
        }).catch((error)=>{
            console.log(error);
        });
        member_res();
    }else{
        setIsUser(false);
        setLoading(false);
    }


},[]);


//키워딩 감지 useEffect
useEffect(()=>{
    if(favorites){
        const postData = {
            favorites:favorites.join(','),
            filter  : 'hashtag',
            keyword : keyword,
            sst : sst,
            sst2 : sst2,
            page : 1,
            offset : offset
        }

        axios.post(apiUrl+'/api/getFavPaintAll',postData).then((response)=>{
            var result = response.data.result;
            setItemData(result);
            setLoading(false);
            setIsFirst(false);
            if(result.length<50){
                setScrlLock(true);
            }else{
                setScrlLock(false);            
            }
        }).catch((error)=>{
            console.log(error);
        });
    }else{
        setItemData();
        setLoading(false);
    }
},[keyword,favorites]);


//Bottom reached(Page 변화) 시 useEffect
useEffect(()=>{
    if(!isFirst){
        setLoading(true); 

        const postData = {
            favorites:favorites.join(','),
            filter  : 'hashtag',
            keyword : keyword,
            sst : sst,
            sst2 : sst2,
            page : page,
            offset : offset
        }

        axios.post(apiUrl+'/api/getFavPaintAll',postData).then((response)=>{
            //console.log(response);
            setItemData(itemData.concat(response.data.result));
            setLoading(false);
            setScrlLock(false);
        }).catch((error)=>{
            console.log(error);
        });
    }
},[page]);

//맨 밑에 닿을때 page 변화
const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom&&!scrlLock) {
        console.log(itemData.length);
        console.log('Bottom reached');
        setPage(page+1);
        setScrlLock(true);
    }
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
 
  if(isUser){
    return (   
        <div>
            <Box onScroll={handleScroll} sx={width>600 ?{ width: 350,height:height-60, overflowY: 'scroll', padding:"0",margin:'0 auto'} : { width: width-30,height:height-60, overflowY: 'scroll', padding:"0",margin:'0 auto'}} >
                {itemData && 
                <Grid container spacing={2} sx={{marginTop:'10px'}}>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant='subtitle2' color="#b82647">
                                <FavoriteIcon sx={{fontSize:'0.9em',color:"#b82647"}}/>좋아요<span style={{color:'#333'}}> 를 누른 사진</span>
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
                }
                {itemData &&
                <ImageList
                    sx={ width>600? {width: 350} : {width : width-30}}
                    // variant="woven"
                    cols={2}
                    rowHeight={270}
                    >
                        {itemData.map((item,index) => (
                            <ImageComponent key={item.code} item={item} favorites={favorites}/>
                        ))}
                </ImageList>
                }
                {!itemData &&
                <Container maxWidth="sm" sx={{height:"50vh"}}>
                    <Box sx={{ bgcolor: '#fff', height:"50vh"}} >
                        <Grid container 
                                direction="column" 
                                justifyContent="center" // 세로 중앙 정렬
                                alignItems="center" // 가로 중앙 정렬
                                style={{ minHeight: '80vh' }}>
                            <Grid item xs={12} sx={{padding:"20px"}}>
                                <img src='./asset/warn.png' style={{width:"50%",objectFit:'cover'}}/>
                            </Grid>
                            <Grid item xs={12} sx={{padding:"20px",color:"#a0a0a0",fontFamily:'roboto',fontSize:'0.9em'}}>
                                <Typography variant='title2' color='#333'>
                                    아직 좋아요를 누른 사진이 없습니다.....
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                }
            {loading && 
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        
                        <CircularProgress color="inherit" sx={{margin:"20px auto"}}/>
                        <span>앨범을 불러오는 중입니다...</span>                
                    </div>
                    </Backdrop>}
            
            </Box>
            <Fab size="large" aria-label="add" sx={{position:'fixed',right:'10px',bottom:'60px',color:'#ffffff',bgcolor:'#B82647'}}>
                <SearchIcon onClick={handleOpen}/>
            </Fab>
            <SearchModal open={open} setOpen={setOpen} setKeyword={setKeyword} />
        </div>
    );
    }else{
        return(
            <div>
            {loading && 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    
                    <CircularProgress color="inherit" sx={{margin:"20px auto"}}/>
                    <span>앨범을 불러오는 중입니다...</span>                
                </div>
                </Backdrop>
            }
            <Container maxWidth="sm" sx={{height:"50vh"}}>
                <Box sx={{ bgcolor: '#fff', height:"50vh"}} >
                    <Grid container 
                            direction="column" 
                            justifyContent="center" // 세로 중앙 정렬
                            alignItems="center" // 가로 중앙 정렬
                            style={{ minHeight: '80vh' }}>
                        <Grid item xs={12} sx={{padding:"20px", border:'1px solid #dedede'}}>
                            <img src="./asset/main_logo.png" style={{width:'50%'}}/>
                        </Grid>
                        <Grid item xs={12} sx={{padding:"20px"}}>
                            <Typography variant='title2' sx={{color:"#a0a0a0",fontFamily:'roboto',fontSize:'0.9em'}} >
                                지금 <u onClick={()=>setCurrentmenu('myinfo')} style={{color : '#333',fontSize:"1.2em"}}>로그인</u>하여 좋아하는 사진을
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{padding:"20px",color:"#a0a0a0",fontFamily:'roboto',fontSize:'0.9em'}}>
                            <Typography variant='title2'>
                                체크하고 모아서 볼 수 있습니다!
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </div>
        );
    }
}


