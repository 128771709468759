import {useState,useEffect} from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { pink } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import useWindowSize from '../include/windowsSize';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import PaintComment from '../include/PaintComment';
import Popover from '@mui/material/Popover';

const apiUrl = 'https://aipictw.aimor.site';

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width:width,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        flexGrow: 1,
        maxHeight:'700px',
        overflowY:'scroll'
    };
      
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ViewModal(props) {

      
  const {width, height} = useWindowSize();

  const {modalOpen, setModalOpen,item,favorites,index} = props;

  const [imgLoad,setImgLoad] = useState();
  const [fav,setFav] = useState();
  const [isFirst,setIsFirst] = useState(true);
  const [uid,setUid] = useState();

  const [fav_cnt,setFav_cnt] = useState(item.fav_cnt);
  const [cmt_cnt,setCmt_cnt] = useState(item.cmt_cnt);


    //코멘트 펼치기
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
        })(({ theme, expand }) => ({
        //transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    //코멘트 관련
    const [expanded, setExpanded] = React.useState(false);    

    const handleExpandClick = () => {
        setExpanded(!expanded);
      };

  const handleClose = () => {
    setModalOpen(false);
    setImgLoad(false);
    setExpanded(false);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const popClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const popClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(()=>{
    if(modalOpen && index >= 0){
        setExpanded(true);
    }
  },[modalOpen,index]);

  useEffect(()=>{
    //console.log(favorites);
    if(favorites){
        for(var i=0; i<favorites.length; i++){
            if(favorites[i] == item.seq_num){
                setFav(true);
            }
        }
    }
    if(localStorage.getItem('uid')){
        var uid_feed = localStorage.getItem('uid');
        setUid(uid_feed);
    }

  },[]);

  const handleFav = (event) => {
    //console.log(fav);
    if(!uid){
        popClick(event);
        return;
    }
    const actFav = !fav;
    const act = !fav ? 'inc' : 'dec';
    var actFavorites = [];
    if(act == 'inc'){
        actFavorites = favorites;
        actFavorites.push(item.seq_num.toString());
    }else if(act == 'dec'){
        for(var i=0; i<favorites.length; i++){
            if(favorites[i].toString() != item.seq_num.toString()){
                actFavorites.push(favorites[i]);
            }
        }
    }
    actFavorites = [...new Set(actFavorites)];
    var actFavorites_str = actFavorites.join(',');

    const postData = {
        uid : uid,
        favorites : actFavorites_str,
        code : item.code,
        act : act
    }

    axios.post(apiUrl+'/api/actFav',postData).then((response)=>{
        //console.log(response);
        setFav_cnt(response.data.fav_cnt);
        setFav(actFav);
    }).catch((error)=>{
        console.log(error);
    });
  }


  return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // minWidth: 350,
        // maxWidth: 450,
        width:width > 500 ? 380 : width-50,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        flexGrow: 1,
        maxHeight: height-60,
        overflowY:'scroll'
    }}>
            <Fab 
                variant="extended" 
                size="small" 
                sx={{border:'none',position:'fixed',top:'10px',right:'10px',bgcolor: 'transparent','&:hover': {bgcolor: '#fff'}}}
                onClick={handleClose}
                >
                <CancelIcon/>
            </Fab>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        {!imgLoad && 
                            <Skeleton variant="rectangular" sx={!imgLoad ? {width:"100%",height:"auto",visibility:'visible',height:"200px"} : {visibility:'hidden'}}/>
                        }
                        <img
                         //srcSet={`${item.p_file}?w=161&fit=crop&auto=format&dpr=2 2x`}
                         src={`${item.p_file}?w=161&fit=crop&auto=format`}
                         alt={item.hashtag}
                         loading="lazy"
                         onLoad={()=>setImgLoad(true)}
                         style={imgLoad ? {transition:'0.5s', visibility:'visible',width:"100%",height:"auto",opacity:1.0} : {transition:'0.5s',visibility:'hidden',width:"0px",height:"0px",opacity:0}}
                         />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Typography id="modal-modal-title" variant="caption" component="h3">
                            {item.hashtag}
                        </Typography>
                    </Item>
                </Grid>     
                {/* <Grid item xs={12}>
                    <Typography id="modal-modal-description"  variant="caption" component="h6" sx={{ textAlign: 'right' }}>
                        <small>{item.regdate}</small>
                    </Typography>
                </Grid> */}
                <Grid item xs={4}>
                    <Item sx={{padding:"5px"}} onClick={handleFav}>
                        <FavoriteIcon sx={fav ? { color: pink[500] } : { color: '#dedede' } }/>
                        <Typography variant="caption" sx={{ textAlign: 'left' }}>
                            {fav_cnt}
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={4}>                    
                    <Item sx={{padding:"5px"}}>
                        <RemoveRedEyeIcon color='primary'/>
                        <Typography variant="caption" sx={{ textAlign: 'left' }}>
                            {item.v_cnt}
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={4}>             
                    {/* <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    > */}
                        <Item sx={{padding:"5px"}} onClick={handleExpandClick}>
                            <ModeCommentIcon color='inherit'/>
                            <Typography variant="caption" sx={{ textAlign: 'left' }}>
                                {cmt_cnt}
                            </Typography>
                        </Item>
                    {/* </ExpandMore> */}
                </Grid>
                <Grid item xs={12}>             
                {/* Comment Collapse */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Item>
                    {/* 코멘트 쓰기 기능이 들어가야 함 */}
                        <PaintComment seq_num={item.seq_num} uid={uid} setCmtcnt={setCmt_cnt}/>
                    </Item>
                </Collapse>
                </Grid>
            </Grid>
            <Popover
                id={item.seq_num}
                open={open}
                anchorEl={anchorEl}
                onClose={popClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <Typography variant='caption' sx={{color:"#333", p: 2 }}>좋아요는 로그인이 필요합니다...</Typography>
            </Popover>
          
          
        </Box>
      </Modal>
  );
}