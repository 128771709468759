import * as React from 'react';
import {useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Stack, Typography,CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import TextField from '@mui/material/TextField';

//Icons
import LogoutIcon from '@mui/icons-material/Logout';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ViewModal from './viewModal';
import MailComponent from './mailComponent';
import LogoutDialog from './LogoutDialog';

const apiUrl = 'https://aipictw.aimor.site';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export default function MyInformation(props){
    const {logout}   = props;
    const [username, setUsername] = useState();
    const [uid, setUid] = useState();    
    const [favorites,setFavorites]=useState();
    const [activeModalId, setActiveModalId] = useState(null);
    const [selIndex, setSelIndex] = useState(null);
    const [loading,setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);


    //아바타 색상 만들기
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.charAt(0)}`,
        };
    }

    //////////////////////////////////////////////////////////////////
    //코멘트 관련
    const [cmtList, setCmtList] = useState();    
    const [expanded, setExpanded] = React.useState(false);    

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    //////////////////////////////////////////////////////////////////
    const [expandedQ, setExpandedQ] = React.useState(false);    

    const handleExpandClickQ = () => {
        setExpandedQ(!expandedQ);
    };
    




    //////////////////////////////////////////////////////////////////
    useEffect(()=>{
        if(!username){
            //사용자의 Favorite정보를 불러와야 함. (uid/username/m_type을 임의로 조작할 수 없음)
            const postData ={
                uid : localStorage.getItem('uid')
            };

            const member_res = async ()=> axios.post(apiUrl+'/api/getUsrInfobyUid',postData)
            .then((response)=>{
                setUsername(response.data.member.username);
                //console.log(response)
                if(response.data.member.favorites){
                    var favorite_arr = response.data.member.favorites.split(',');
                    setFavorites(favorite_arr);
                }
            }).catch((error)=>{
                console.log(error);
            });
            member_res();
        }

        //axios를 통해 유저의 코멘트 정보를 가져와 담는다. (개수/ 내용)
        axios.post(apiUrl+'/api/getPaintCommentUser',{username:username}).then((response)=>{
            if(response.data.comments != 501){
                setCmtList(response.data.comments);
            }else{
                setCmtList([]);
            }
            
            setLoading(false);
        }).catch((error)=>{
            console.log(error);
        });

    },[username]);

    return (
        <Box sx={{ flexGrow: 1, marginTop:'40px',height:'85vh' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {username && 
                    <Box sx={{display:'flex',flexDirection:'row',margin:'0 auto',justifyContent:'center'}}>
                    {/* <Box sx={{display:'flex',flexDirection:'row',margin:'0 auto'}}> */}
                        <Avatar sx={{bgcolor: "#dedede",boxShadow:'1px 1px #dedede'}} {...stringAvatar(username)} elevation={3}></Avatar>
                        <Typography variant="subtitle1" color="#444" sx={{marginLeft:'15px',lineHeight:'3',fontWeight:'700',textShadow:'1px 1px #dedede'}}>{username}</Typography>
                    {/* </Box> */}
                    </Box>
                    }
                </Grid>
                <Divider sx={{border:'1px',width:'95%',padding:'10px',margin:'0 auto'}}/>
                <Grid item xs={12}>
                    <Item onClick={handleExpandClick}>
                        <Button variant='secondary' sx={{color:'#1976d2',margin:'0 auto'}}>
                            <InsertCommentIcon/>
                            <Typography variant='subtitle2' color='inherit' sx={{marginLeft:'15px',fontWeight:'500'}}>
                                내가 작성한 댓글 
                                {cmtList &&
                                    <span>
                                        ({cmtList.length})
                                    </span>
                                }
                            </Typography>
                        </Button>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                {cmtList && 
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box sx={{border:'1px solid #dedede', maxHeight:"350px", overflowY:'scroll'}}>
                                {cmtList.map((x,index)=>{
                                    return (
                                    <Grid key={index} container spacing={2} sx={{padding:'5px 10px'}} onClick={()=>{
                                        setSelIndex(index);
                                        setActiveModalId(index);
                                    }}>                                        
                                        <Grid item xs={2} sx={{padding:'5px'}}>
                                            <img src={x.p_file} style={{maxWidth:'40px',maxHeight:'40px',objectFit:'cover'}}/>
                                        </Grid>
                                        <Grid item xs={7} sx={{padding:'5px'}}>
                                            <Typography variant="subtitle2" color="inherit" sx={selIndex === index ? {textDecoration:'underline',fontWeight:'700',color:'#333'} : {}}>{x.pc_content.length > 15 ? x.pc_content.substring(0,15)+"..." : x.pc_content}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{padding:'5px'}}>
                                        <Typography variant="caption" color="inherit">{x.pc_regdate.substring(0,10)}</Typography>
                                        </Grid>
                                    </Grid>
                                    )
                                })}
                                {cmtList && cmtList.map((x, index) => {                        
                                    return (
                                        <ViewModal 
                                            key={index} 
                                            modalOpen={activeModalId === index} 
                                            setModalOpen={() => setActiveModalId(null)}
                                            item={x}
                                            favorites={favorites}
                                            index={index}
                                        />
                                    );
                                })}
                        </Box>
                    </Collapse>
                }

                {(!cmtList|| cmtList.length == 0)  && 
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box>
                        <Grid container spacing={2} sx={{padding:'5px 10px'}}>                                        
                            <Grid item xs={12} sx={{padding:'5px'}}>
                                <Typography variant="subtitle2" color="inherit" sx={{textDecoration:'underline',fontWeight:'700',color:'#333'}}>
                                    아직 등록한 댓글이 없어요...
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
                }
                </Grid>

                <Grid item xs={12}>
                    <Item onClick={handleExpandClickQ}>
                        <Button variant='secondary' sx={{color:'#FFC000',margin:'0 auto'}}>
                            <HelpCenterIcon/>
                            <Typography variant='subtitle2' color='inherit' sx={{marginLeft:'15px',fontWeight:'500'}}>
                                개발자에게 문의
                            </Typography>
                        </Button>
                    </Item>
                </Grid>    
                <Grid item xs={12}>
                    <Collapse in={expandedQ} timeout="auto" unmountOnExit>
                        <MailComponent/>
                    </Collapse>
                </Grid>            
                <Grid item xs={12}>
                    <Item onClick={()=>setAlertOpen(true)}>
                        <Button variant='secondary'  sx={{color:'#a80526',margin:'0 auto'}}>
                            <LogoutIcon />
                            <Typography variant='subtitle2' sx={{marginLeft:'15px',fontWeight:'500'}}>로그아웃</Typography>
                        </Button>
                    </Item>
                </Grid>                
            </Grid>
            {loading && 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                
                <CircularProgress color="inherit" sx={{margin:"20px auto"}}/>
                <span>정보를 불러오는 중입니다...</span>
            </div>
            </Backdrop>}

            {/* 로그아웃 다이얼로그 */}
            <LogoutDialog logout={logout} alertOpen={alertOpen} setAlertOpen={setAlertOpen}/>
        </Box>
    );
}