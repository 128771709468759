import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {useState,useEffect,useCallback} from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Backdrop, CircularProgress,Stack} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import SendIcon from '@mui/icons-material/Send';

const apiUrl = 'https://aipictw.aimor.site';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




  export default function PaintComment(props){

    const {seq_num,uid,setCmtcnt} = props;
    const [comment, setComment] = useState("");
    const [commentCool,setCommentCool] = useState(false);
    const [cmtList,setCmtList] = useState();
    const [err_msg,setErr_msg] = useState();
    const [loading,setLoading] = useState(true);

    const [user,setUser] =useState();
    const [m_type,setM_type] =useState();

    //아바타 색상 만들기
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.charAt(0)}`,
        };
    }

    //useEffect로 첫번째 로드하기
    useEffect(()=>{
        const postData = {
            'p_seq_num' : seq_num
        }
        axios.post(apiUrl + '/api/getPaintComment', postData).then((response)=>{
            //console.log(response);
            if(response.data.comments.length>0){
                setCmtList(response.data.comments);
            }else{
                setErr_msg(response.data.err_msg);
            }

            setLoading(false);
        }).catch((error)=>{
            console.log(error);
        });
        if(localStorage.getItem('uid')){
            axios.post(apiUrl + '/api/getUsrInfobyUid', {uid:localStorage.getItem('uid')}).then((response)=>{
                if(response.data.member){
                    setUser(response.data.member.username);
                    setM_type(response.data.member.m_type);
                }
            }).catch((error)=>{
                console.log(error);
            });
        }
    },[]);


    const handleSubmit = () => {

        if (!comment.trim()) {
            alert("댓글을 입력하세요.");
            return;
        }

        if(commentCool){
            alert("댓글을 연속하여 작성할 수 없습니다.");
            return;
        }

        // 예시 부적절한 단어 리스트
        const inappropriateWords = [
            '시발',
            '씨발',
            '시발년',
            '씨발년',
            '개새끼',
            '개새',
            '섹스',
            'sex',
            'fuck',
            '씹'
        ]; 

        if (inappropriateWords.some(word => comment.includes(word))) {
            alert("부적절한 단어가 포함되어 있습니다.");
            return;
        }
        
        // 서버로 데이터 전송
        sendCommentToServer(comment);
        
    };

    const sendCommentToServer = async (comment) => {
        try {

            if(!uid){
                window.alert('사용자의 정보를 읽는데 실패하였습니다.');
                return;
            }
            var username = localStorage.getItem('username');

            const postData ={
                p_seq_num : seq_num,
                pc_writer : username,
                pc_content : comment
            }

            const response = await axios.post(apiUrl + '/api/submitComment', postData);
            //console.log(response.data);
            
            //comment정보를 다시 cmtList에 넣는다.
            setErr_msg();
            setCmtList(response.data.comments);
            setCmtcnt(response.data.comments.length);
            if(response.data.comments.length==0){
                setErr_msg(true);
            }
            setComment(""); // 입력 필드 초기화
            
            //commentCoolTime 시작
            setCommentCool(true);
            
            setTimeout(()=>{
                setCommentCool(false);
            },5000);
            
        } catch (error) {
            console.error("댓글 전송 오류:", error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Enter 키의 기본 동작을 방지
            handleSubmit(); // 필요한 경우 handleSubmit 함수를 호출
        }
    };

    const deleteComment = (pc_seq_num,p_seq_num) => {
        let answer = window.confirm('댓글을 삭제하시겠습니까?');
        if(!answer) return;
        axios.post(apiUrl+'/api/deletePcomment',{pc_seq_num:pc_seq_num,p_seq_num:p_seq_num}).then((response)=>{

            setCmtList(response.data.comments);
            if(response.data.comments.length==0){
                setErr_msg(true);
            }else{
                setCmtList(response.data.comments);
            }
            setCmtcnt(response.data.comments.length);
        }).catch((error)=>{
            console.log(error);
        });
    }

    const response =  cmtList;

    return (
        <Box key={seq_num}
        component="form"
        sx={{padding:'5px', border : '1px solid #dedede','& .MuiInputLabel-root': { width: '10ch',fontSize:'0.8em' },}}
        noValidate
        autoComplete="off"
        onSubmit={(e)=>e.preventDefault()}
        >
            {uid && 
                <div>
                    <TextField 
                    label="댓글" 
                    color="primary" 
                    variant='standard' 
                    sx={{float:'left', margin:'5px 5px',width:"80%"}} 
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={handleKeyDown}
                    />
                    <IconButton aria-label="add to favorites" color="primary" onClick={handleSubmit} sx={{float:'right', margin:'5px auto',width:"15%"}}>
                        <SendIcon/>
                    </IconButton>
                </div>
            }
            
            

            <Divider sx={{width:'100%', marginBottom:'15px', height:"10px", backgroundColor:'#dedede'}}/>
            {loading&&
                <CircularProgress color="inherit" />
            }
            {err_msg && 
                <Typography variant='caption' color="#333" sx={{margin:'10px'}}>
                    작성된 댓글이 없습니다....
                </Typography>
            }
            {cmtList && cmtList.length >0 &&  
            <Stack spacing={2} sx={{padding:'15px', maxHeight:'350px',overflowY:'scroll'}}>
                {cmtList.map((x)=>{
                    if(x.isBlock != 'Y')
                    return(
                        <div key={x.pc_seq_num} >
                            <Stack spacing={2} direction="row">
                                
                                <Avatar sx={{ bgcolor: "#dedede" }} {...stringAvatar(x.pc_writer)} elevation={3}></Avatar>
                                <Item sx={user == x.pc_writer ? {maxWidth:'75%',border:'1px solid #cccc33'} : {maxWidth:'75%'}} elevation={3}>
                                    <Typography variant='overline' color='#333' sx={{wordBreak:'keep-all',wordWrap:'break-word', textAlign:'left'}}>{x.pc_content}</Typography>
                                    <Typography variant="caption" display="block" sx={user == x.pc_writer ? {textAlign:'right',fontSize:"0.7em",fontWeight:'700'}:{textAlign:'right',fontSize:"0.7em"}}>
                                    {x.pc_writer} / {x.pc_regdate}
                                    </Typography>
                                </Item>
                                {(user == x.pc_writer || m_type=='admin') && 
                                <Stack>
                                    <Typography variant='caption' color='#cc9999' onClick={()=>deleteComment(x.pc_seq_num,x.p_seq_num)} sx={{fontWeight:'700'}}>
                                        x
                                    </Typography>
                                </Stack>
                                }
                            </Stack>
                        </div>
                    );
                })}
            </Stack>
            }
        </Box>
    );
  }