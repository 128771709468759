import * as React from 'react';
import {useState,useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://aipictw.aimor.site/" target="_blank">
          Aista in Aimor
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp(props) {
  const {menu,setMenu,uid,setUid,apiUrl} = props;

  const [username,setUsername] = useState();
  const [password,setPassword] = useState();  
  const [password_chk,setPassword_chk] = useState();    
  const [submit_err,setSubmit_err] = useState();
  const [err_msg,setErr_msg] = useState();
  const [req_load,setReq_load] = useState(false);
  const [regOk,setRegOk] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //  setUsername(data.get('username'));
    //  setPassword(data.get('password'));
    // setPassword_chk(data.get('password_chk'));

    const postData = {
        username: data.get('username'),
        password: data.get('password'),
        password_chk: data.get('password_chk'),
    }


    if(!data.get('username') || !data.get('password') ||  !data.get('password_chk')){
        setSubmit_err(true);
        setErr_msg('입력되지 않은 정보가 있습니다.');
        return false;
    }

    const usernameRegex = /^[a-z0-9]{5,10}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if(!usernameRegex.test(data.get('username'))){
        setSubmit_err(true);
        setErr_msg('아이디는 5~10자의 알파벳 소문자와 숫자로만 구성되어야 합니다');
        return false;
    }

    if(data.get('password') != data.get('password_chk')){
        setSubmit_err(true);
        setErr_msg('비밀번호를 확인해주세요');
        return false;
    }

    if(!passwordRegex.test(data.get('password'))){
        setSubmit_err(true);
        setErr_msg('비밀번호는 최소 8자 이상, 하나 이상의 문자와 하나의 숫자 포함해야 합니다.');
        return false;
    }

    //아이디 비번에 이상이 없으면 axios를 시도한다.
    setSubmit_err(false);
    setReq_load(true);

    axios.post(apiUrl+'/api/registUser',postData)
    .then((response)=>{        
        const data = response.data;
        if(data.err_cd != 200){
            setSubmit_err(true);
            setRegOk(false);
            setErr_msg(data.err_msg);
            setReq_load(false);
        }else{
            const username = response.data.member.username;
            const uid = response.data.member.uid;
            const m_type = response.data.member.m_type;

            setRegOk(true);
            setErr_msg(data.err_msg);
            setReq_load(false);
            localStorage.setItem('username',username);
            localStorage.setItem('uid',uid);
            localStorage.setItem('m_type',m_type);
            setTimeout(()=>{
                setUid(uid);
            },2000)
        }
    }).catch((error)=>{
        console.log(error);
    });

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs"  sx={{height:"80vh"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Container maxWidth="sm">
            <img src="./asset/main_logo.png" style={{width:'50%'}}/>
          </Container>
          <Typography component="title1" variant="h5">
              Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  error = {submit_err ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error = {submit_err ? true : false}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password_chk"
                  label="Password Confirm"
                  type="password"
                  id="password_chk"
                  error = {submit_err ? true : false}
                />
                
              </Grid>
              {submit_err && 
              <Grid item xs={12}>
                <Alert severity="error">{err_msg}</Alert>
              </Grid>
              }
              {regOk && 
              <Grid item xs={12}>
                <Alert severity="success">{err_msg}</Alert>
              </Grid>
              }
              <Grid item xs={12}>
                <Typography variant='secondary' sx={{fontSize:'0.8em'}}>
                Aista는 사용자의 아이디, 비밀번호를 제외한 개인정보를 일체 수집하지 않습니다. 따라서 비밀번호 분실 시 다시 찾을 수 없으므로 신중히 작성해주시기 바랍니다. <u>1년 이상 로그인 하지 않은 유저의 경우 유저아이디가 자동으로 삭제</u>됩니다
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Link href="#" variant="body2" sx={{fontSize:'0.7em'}} onClick={(()=>setMenu('login'))}>
            이미 아이디가 있으신가요?
            </Link>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={req_load}
            // onClick={()=>setReq_load(false)}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
}