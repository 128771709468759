import * as React from 'react';
import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import axios from 'axios';

const apiUrl = 'https://aipictw.aimor.site';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#333',
  }));

export default function SearchModal(props) {
 
 const {open,setOpen,setKeyword} = props;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const [finding,setFinding] = useState();
  const [hashtag,setHashtag] = useState();
  const [hashArr,setHashArr] = useState([]);

  useEffect(()=>{
    setFinding(true);
    if(hashtag&&hashtag!=''&&hashtag.length>2){
        const postData = {hashtag : hashtag}
        axios.post(apiUrl+'/api/findHash',postData).then((response)=>{
            console.log(response);
            var temp_arr = [];
            var result = response.data.result;
            for(var i=0; i<result.length;i++){
                var ori_hash = result[i].hashtag.trim();
                var ori_hash_arr = ori_hash.split('#');
                for(var j=0; j<ori_hash_arr.length; j++){
                    var ori_word = ori_hash_arr[j].toUpperCase();

                    if(ori_word !='' && ori_word.toLowerCase().includes(hashtag.toLowerCase())){
                                            
                        if(ori_word.charAt(0) == ' '){
                            ori_word = ori_word.substring(1,ori_word.length);
                        }
                        
                        if(ori_word.charAt(ori_word.length - 1) == ' '){
                            ori_word = ori_word.substring(0,ori_word.length-1);
                        }

                        temp_arr.push(ori_word);
                    }
                }
            }
            temp_arr = [...new Set(temp_arr)];
            console.log('hash 결과',temp_arr);
            setHashArr(temp_arr);
            setFinding(false);

        }).catch((error)=>{
            console.log(error);
        });
    }else{
        setFinding(true);
        setHashArr();
    }
  },[hashtag]);

  function setSearch(keyword){
    setKeyword(keyword);
    handleClose();
    setHashtag();
  }

  return (
    <div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="subtitle1" sx={{marginBottom:"20px"}}>
                    원하는 이미지를 검색할 수 있습니다!
                </Typography>

                <TextField
                    id="outlined-basic"
                    label="해시태그를 입력하세요"
                    variant="outlined"
                    sx={{width:'100%',marginBottom:"20px"}}
                    value={hashtag}
                    onChange={(e)=>setHashtag(e.target.value)}
                />
                
                <Button 
                    variant="outlined" 
                    color='inherit' 
                    onClick={()=>setSearch()}
                    sx = {{marginBottom:'10px'}}
                    >초기화</Button>

                <Divider />
                {!finding && hashArr.length>0 && 
                <Stack direction="row" spacing={1} sx={{margin:'5px', maxHeight:'250px',overflowY:'scroll'}} useFlexGap flexWrap="wrap">
                    {hashArr.map((x)=>{
                        return(
                            <Item onClick={()=>setSearch(x)}>#{x}</Item>
                        );
                    })
                    }
                </Stack>
                }
                
            </Box>
        </Modal>
    </div>
  );
}
