import * as React from 'react';
import { Container, SpeedDial } from '@mui/material';
import MasonImageList from '../components/ImageList';


export default function SearchPage(props){

    return (
        <Container sx={{height: '90vh', position: 'relative',overflow: 'auto',padding:'2px' }}>
            <MasonImageList/>
        </Container>
    );
}