import * as React from 'react';
import {useState,useEffect} from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewModal from './viewModal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';


const apiUrl = 'https://aipictw.aimor.site';

export default function ImageComponent(props){
    const {item,favorites} = props;
    const [imgLoad,setImgLoad] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    
    //ViewModal 열기
    const modalOpenFunc = () => {
      setModalOpen(true);
      axios.post(apiUrl+'/api/viewPs',{code:item.code}).then((response)=>{}).catch((response)=>{});
    }

    return (
        <ImageListItem cols={item.cols || 1} rows={item.rows || 1}>
                {/* <LinearProgress color="inherit" sx={!imgLoad ? {visibility:'visible',height:"150px"} : {visibility:'hidden'}}/> */}
                <Skeleton variant="rectangular" sx={!imgLoad ? {visibility:'visible',minHeight:"230px",maxHeight:'250px'} : {visibility:'hidden'}}/>
                <img
                  // srcSet={`${item.p_file}?w=161&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.p_file}?w=161&fit=crop&auto=format`}
                  alt={item.hashtag}
                  loading="lazy"
                  onLoad={()=>setImgLoad(true)}
                  style={imgLoad ? {visibility:'visible',minHeight:'230px',maxHeight:'250px'} : {visibility:'hidden',height:"0px"}}
                  onClick={()=>modalOpenFunc()}
                />
                <Box sx={imgLoad ? { flexGrow: 1, visibility:'visible' } : { flexGrow: 1, visibility:'hidden' }}>
                         <Grid container  sx={{ position:'relative',bottom:'20px',right:'0px', backgroundColor:'#fff', opacity:'0.5', borderRadius:'10px 10px 0px 0px'}}>
                            <Grid item xs={4}>
                              <FavoriteIcon sx={{fontSize:'0.9em',color:"#DB4E9C",marginRight:'5px'}}/>
                              <Typography variant='caption' color="#000"  sx={{fontSize:'0.8em'}}>{item.fav_cnt}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <RemoveRedEyeIcon color="primary" sx={{fontSize:'0.9em',marginRight:'5px'}}/>
                              <Typography variant='caption' color="#333"  sx={{fontSize:'0.8em'}}>{item.v_cnt}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <ModeCommentIcon color="inherit" sx={{fontSize:'0.8em',marginRight:'5px'}}/>
                              <Typography variant='caption' color="#333" sx={{fontSize:'0.8em'}}>{item.cmt_cnt}</Typography>
                            </Grid>
                         </Grid>
                      </Box>
                <ViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} item={item} favorites={favorites}/>
                
        </ImageListItem>
   
    );
}