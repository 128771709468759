import * as React from 'react';
import { styled } from '@mui/material/styles';
import {useState,useEffect,useCallback} from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Backdrop, CardHeader, CircularProgress,Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';


//Icon import
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import PaintComment from './PaintComment';

const apiUrl = 'https://aipictw.aimor.site';

//카드 생성 function
export default function RecipeReviewCard(props){

    const {uid, seq_num, p_file, hashtag, code, regdate, v_cnt, fav_cnt, cmt_cnt,favorites,setFavorites} = props;

    const [isFirst, setIsFirst] = useState(true);

    //코멘트 펼치기
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
        })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    //Badge 스타일
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          fontSize:'0.4em',
          right: -4,
          top: 5,
          border: `1px solid ${theme.palette.background.paper}`,
          padding: '0 1px',
        },
    }));

    //코멘트 관련
    const [expanded, setExpanded] = React.useState(false);
    
    //이미지 로드 관련
    const [imgLoad,setImgLoad] = useState(true);
    const [favorite,setFavorite] = useState(false);
    const [expandImg,setExpandImg] = useState(false);

    //팝오버 관련
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pop_msg,setPop_msg] = useState();
    const [pop_style,setPop_style] = useState();

    //좋아요 / 코멘트 수 상태
    const [favcnt,setFavcnt] = useState(fav_cnt);
    const [cmtcnt,setCmtcnt] = useState(cmt_cnt);

    //event 받기
    const [evnt,setEvnt] = useState();

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //favorite상태를 확인해서 member / favorites에 목록을 가져와서 비교하고 이미 되어있으면 표시        
    useEffect(() => {
        // console.log('---------favorites useEffect-------------');
        setFavorite(favorites.includes(seq_num.toString())); // seq_num이 숫자일 수 있으므로 문자열로 변환할 수 있습니다.
    }, [favorites]); 


    //favorite 감지 useEffect
    useEffect(()=>{
        // console.log('---------favorite useEffect-------------');

        if(!uid){
            setPop_style({ p: 1, fontSize:'0.8em',color:'#333' });
            setAnchorEl(evnt);
            setPop_msg('로그인이 필요합니다.');
            setFavorite(false);
            setTimeout(()=>{handleClose();},1200);
            return;
        }

       // 이전 코드의 로직을 여기로 이동
       const act = favorite ? 'inc' : 'dec';
       let newFavorites;

       if (favorite) {
           // seq_num을 favorites 배열에 추가
           newFavorites = [...favorites, seq_num.toString()];
       } else {
           // seq_num을 favorites 배열에서 제거
           newFavorites = favorites.filter(item => item !== seq_num.toString());
       }
       
    //    console.log('favorite',favorite);
    //    console.log('newFavorites',newFavorites);
       
       newFavorites = [...new Set(newFavorites)];
       var fav_str = newFavorites.join(',');
    //    console.log('fav_str',fav_str);
       setFavorites(newFavorites);

       const postData = {
           uid : uid,
           favorites : fav_str,
           code:code,
           act : act
       }
       if(favorite && evnt){
            setPop_style({ p: 1, fontSize:'0.8em',color:'#cc0000' });
            setAnchorEl(evnt);
            setPop_msg('좋아요!');
        }
       //서버에 좋아요 상태 업데이트 하기
       const serverAct = async () => {
           await axios.post(apiUrl+'/api/actFav',postData).then((response)=>{
               //FavCnt배정
               setFavcnt(response.data.fav_cnt);
               //favorite가 true이고 evnt가 있다면 좋아요 팝오버
                
               //팝오버 타임아웃되면 닫기
               setTimeout(()=>{handleClose();},1500);
               
           }).catch((error)=>{
               //console.log(error);
           });
       };
       if(!isFirst){
            serverAct();
       }
    },[favorite,evnt]);
    
    const favoriteAction = useCallback((event) => {
        setIsFirst(false);
        setEvnt(event.currentTarget);
        setFavorite(f=>!f);
    },[uid])
    

    const shareAction = (event) => {
        setPop_style({ p: 1, fontSize:'0.8em',color:'#333' });
        setAnchorEl(event.currentTarget);
        setPop_msg('공유하기는 준비중이에요.');
        //팝오버 타임아웃되면 닫기
        setTimeout(()=>{handleClose();},1200);
    }

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    //클릭시 이미지를 늘리고 View Count를 1 늘린다.
    function setViewExpand(code){
        axios.post(apiUrl+"/api/viewPs",{code:code}).then((response)=>{
            //console.log(response);
        }).catch((error)=>{
            //console.log(error);
        });
        setExpandImg(!expandImg);

    }

    var regdate_rs = regdate.substring(0,10);

    var hash_arr = hashtag.trim().split('#');



    return (
      <Card sx={{}}>
        <CardHeader 
          sx={{
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            opacity : 0.2
          }}
        />
        <CardMedia
          component="img"
          height={expandImg ? "768" : "350"}
          image={p_file}
          loading='lazy'
          onClick={()=>setViewExpand(code)}
          sx={{
            transition: 'height 0.3s ease-in-out', // 높이 변경에 대한 애니메이션을 정의합니다.
            cursor: 'pointer', // 이미지에 마우스를 올렸을 때 커서 모양을 pointer로 설정합니다.
            height: expandImg ? "700px" : "300px", // 애니메이션을 적용할 속성을 인라인 스타일로 정의합니다.
            // imgLoad를 사용하는 코드는 주석 처리하거나 삭제합니다.
          }}
        //   onLoad={()=>setImgLoad(false)}
        //   sx={imgLoad ? {display:'none'} : {display:'block'}}
        />

        <CardContent sx={{borderBottom:'2px solid #dedede'
          }}>

            <Typography variant="body2" color="text.secondary" sx={{fontSize:'0.8em',textAlign:'left'}}> 
            {hash_arr.map((hash,index)=>{
                 if(hash == ''){
                    return;
                 }
                 return (
                    <Chip key={hash} label={hash} variant="outlined" sx={{marginLeft:'5px',marginBottom:'5px'}}/>
                    
                );
            })}
            </Typography>

            {/* </Typography> */}
            <Typography variant="body2" color="text.secondary" sx={{marginTop:"15px",fontSize:'0.8em',textAlign:'right'}}> 
                  <VisibilityIcon sx={{fontSize:'0.9em',marginLeft:"10px"}}/> {v_cnt} 
                  <AccessTimeIcon  sx={{fontSize:'0.9em',marginLeft:"10px"}}/> {regdate_rs} 
            </Typography>
          
        </CardContent>
        <CardActions disableSpacing>
        
          <IconButton aria-label="add to favorites" onClick={favoriteAction}>
            <StyledBadge badgeContent={favcnt} color="secondary" showZero>
                <FavoriteIcon style={{fontSize:'1.2em', color: favorite === true ? '#DB4E9C' : 'inherit'}}/>
            </StyledBadge>
          </IconButton>

          <IconButton aria-label="share"  onClick={shareAction}>
            <ShareIcon />
          </IconButton>
          
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <IconButton  aria-label="share" onClick={handleExpandClick}>
            <StyledBadge badgeContent={cmtcnt} color="primary" showZero>
                <InsertCommentIcon/>
            </StyledBadge>
          </IconButton>
        </CardActions>
        
        {/* Comment Collapse */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
                {/* 코멘트 쓰기 기능이 들어가야 함 */}
                <PaintComment seq_num={seq_num} uid={uid} setCmtcnt={setCmtcnt}/>
          </CardContent>
        </Collapse>

        <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={()=>handleClose}
              anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
             <Typography sx={pop_style}> {pop_msg} </Typography>
          </Popover>
      </Card>

      
    );
  }