import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BottomNavigation, Box, Container } from '@mui/material';
import { useState,useEffect } from 'react';
import FeedPage from './pages/FeedPage';
import SearchPage from './pages/SearchPage';
import MyInfo from './pages/MyInfo';
import LabelBottomNavigation from './components/bottomMenu';
import Header from './include/Header';
import FavoritePage from './pages/FavoritePage';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';


const apiUrl = 'https://aipictw.aimor.site';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

function App() {

  const [currentMenu,setCurrentmenu] = useState();
  const [hist,setHist] = useState(['feed']);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      //본래 이벤트 막기
      event.preventDefault();

      if(hist.length > 1){
        hist.pop();
        //const popHist = hist;
        setCurrentmenu(hist[hist.length-1]);
      }else{

        const message = 'Are you sure you want to leave?';
        event.returnValue = message; // 표준에 따른 구현
        return message; // 일부 브라우저를 위한 구현

      }
    };
    // 이벤트 리스너 추가
    window.addEventListener('beforeunload', handleBeforeUnload);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  useEffect(()=>{
    //초기 로드시 visit_log를 작성하기
    axios.post(apiUrl+'/api/insertLog').then((response)=>{
      //console.log(response);
    }).catch((error)=>{
      //console.log(error);
    });
  },[])

  useEffect(()=>{
    //초기 페이지 진입시 강제로 feed페이지로 넘김
    if(!currentMenu){
      setCurrentmenu('feed');
    }
  },[currentMenu])

  return (
    <ThemeProvider theme={theme}>
        <div className="App" style={{
        }}>
          <Header currentMenu={currentMenu} setCurrentmenu={setCurrentmenu}/>
          <Container sx={{
            paddingBottom:"30px",
          }}>
          {/* Header */}
          
          <div className="sizedBox30" style={{height:"50px"}}/>
          {/* CurrentPage */}
              {currentMenu == 'feed'&& 
              <FeedPage />
              }
              {currentMenu == 'search'&& 
              <SearchPage />
              }
              {currentMenu == 'favorite'&& 
              <FavoritePage currentMenu={currentMenu} setCurrentmenu={setCurrentmenu} />
              }
              {currentMenu == 'myinfo'&& 
              <MyInfo />
              }
          {/* BottomNavigation */}
            <LabelBottomNavigation  currentMenu={currentMenu} setCurrentmenu={setCurrentmenu} history={hist} setHistory={setHist}/>
          </Container>
        </div>
    </ThemeProvider>    
  );
}

export default App;
